import React, { useState } from "react";
import { TextField, Button, Typography, Container, Stack } from "@mui/material";
import logo from "../../assets/isp_logo_new.png";
import axios from "axios";
import RegistrationPopup from "./RegistrationPopup";

const Signup = () => {
  const [formData, setFormData] = useState({
    company_name: "",
    url: "",
    admin_firstname: "",
    admin_surname: "",
    admin_email: "",
    // confirmEmail: "",
    designation: "",
    // username: "",
    // password: "",
  });
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [logoFile, setLogoFile] = useState("");
  const [error, setError] = useState("");

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogoFile(file);
    }
  };

  const validateForm = () => {
    const {
      company_name,
      url,
      admin_email,
      // confirmEmail,
      designation,
      // username,
      // password,
    } = formData;

    // Check if all required fields are filled
    if (
      !company_name ||
      !url ||
      !admin_email ||
      // !confirmEmail ||
      !designation
    ) {
      setError("All fields are mandatory.");
      return false;
    }

    // Check if email and confirm email match
    // if (admin_email !== confirmEmail) {
    //   setError("Email and Confirm Email do not match.");
    //   return false;
    // }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(admin_email)) {
      setError("Please provide a valid email address.");
      return false;
    }

    // Validate password strength
    // const passwordRegex =
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // if (!passwordRegex.test(password)) {
    //   setError(
    //     "Password must be at least 8 characters long and include at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
    //   );
    //   return false;
    // }

    return true;
  };

  const isEmailValid = (email, companyName) => {
    if (!email || !companyName) return false;

    const emailDomain = email.toLowerCase().split("@")[1]; // Extract domain
    const normalizedCompanyName = companyName
      .toLowerCase()
      .replace(/\s+/g, " "); // Normalize spaces
    const companyParts = normalizedCompanyName.split(" "); // Split the company name into parts

    // Check if any part of the company name exists in the email domain
    return companyParts.some((part) => emailDomain?.includes(part));
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const { admin_email, company_name } = formData;
    // Validate email against company name
    if (!isEmailValid(admin_email, company_name)) {
      // setPopupOpen(true);
      setError(
        "The email address provided does not match the official company name. Kindly provide an official company email address to proceed with your registration."
      );
      return;
    }

    // make the api call
    const formDataToSubmit = new FormData();

    Object.keys(formData).forEach((key) => {
      formDataToSubmit.append(key, formData[key]);
    });

    if (logoFile) {
      formDataToSubmit.append("logo", logoFile);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/addCompany`,
        formDataToSubmit,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        setError("");
        setPopupOpen(true);
      } else {
        console.error("Registration failed");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleEmailValidation = async () => {
    try {
      const { admin_email } = formData;

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/checkCompanyUsername/${admin_email}`
      );

      if (response.data?.admin_email) {
        setError(
          "The email address is already registered. Please use a different email."
        );
      } else {
        handleSubmit();
      }
    } catch (error) {
      console.error("Error checking email registration:", error);
      setError(
        "An error occurred while checking the email. Please try again later."
      );
    }
  };

  const handleSubmitButtonClick = () => {
    handleEmailValidation();
  };

  return (
    <div className="flex flex-col items-center bg-white rounded-md w-full">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "100px",
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
        className="shadow-lg mb-8"
      >
        <img
          src={logo}
          alt="Institute of Sales Professionals Logo"
          className="h-[100px] pl-6 pt-1"
        />
        <div
          style={{
            width: "50%",
            height: "0",
            borderBottom: "100px solid #020236",
            borderLeft: "100px solid transparent",
            display: "flex",
            alignItems: "center",
          }}
        />
      </div>
      <Container maxWidth="lg">
        <p className="text-left text-[#020236] text-lg font-semibold">
          Welcome to the ISP Awards in Sales Excellence. Please sign up with
          your official email to receive your login details. Complete the
          assessment, and if you meet the required score, you’ll earn a
          certificate to download and display. For assistance, contact{" "}
          <a href="mailto:ram.raghavan@the-isp.org" className="text-blue-600">
            ram.raghavan@the-isp.org
          </a>
          .
        </p>
        <Stack sx={{ marginTop: "40px", gap: "8px" }}>
          <div className="w-full flex flex-row">
            <p className="text-left text-gray-700 mb-2 w-[50%] text-lg">
              Please enter your business name
            </p>
            <div style={{ width: "50%" }}>
              <TextField
                label="Business name"
                name="company_name"
                variant="outlined"
                fullWidth
                value={formData.company_name}
                onChange={handleInputChange}
                className="bg-gray-100"
              />
            </div>
          </div>

          <div className="w-full flex flex-row">
            <p className="text-left text-gray-700 mb-2 w-[50%] text-lg">
              Please enter your business URL
            </p>
            <div style={{ width: "50%" }}>
              <TextField
                label="Business URL"
                name="url"
                variant="outlined"
                fullWidth
                value={formData.url}
                onChange={handleInputChange}
                className="bg-gray-100"
              />
            </div>
          </div>

          <div
            style={{
              width: "50%",
              marginLeft: "auto",
            }}
          >
            <Button
              variant="contained"
              sx={{
                height: "48px",
                marginLeft: "auto",
                backgroundColor: "#020236",
                color: "white",
                "&:hover": {
                  backgroundColor: "#020236d8",
                },
                marginTop: "12px",
                marginBottom: "12px",
                borderRadius: "999px",
                padding: "0 20px",
              }}
              component="label"
            >
              Upload Your Logo
              <input type="file" hidden onChange={handleLogoChange} />
            </Button>
            {logoFile && (
              <Typography
                variant="body2"
                sx={{ color: "#020236", marginTop: "8px" }}
              >
                {logoFile.name}{" "}
              </Typography>
            )}
          </div>

          <div className="w-full flex flex-row">
            <p className="text-left text-gray-700 mb-2 w-[50%] text-lg">
              Please enter contact information
            </p>

            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <TextField
                label="First name"
                name="admin_firstname"
                variant="outlined"
                fullWidth
                value={formData.admin_firstname}
                onChange={handleInputChange}
                className="bg-gray-100"
              />
              <TextField
                label="Surname"
                name="admin_surname"
                variant="outlined"
                fullWidth
                value={formData.admin_surname}
                onChange={handleInputChange}
                className="bg-gray-100"
              />
              <TextField
                label="Email"
                name="admin_email"
                variant="outlined"
                fullWidth
                value={formData.admin_email}
                onChange={handleInputChange}
                className="bg-gray-100"
                type="email"
              />
              {/* <TextField
                label="Confirm Email"
                name="confirmEmail"
                variant="outlined"
                fullWidth
                value={formData.confirmEmail}
                onChange={handleInputChange}
                className="bg-gray-100"
                type="email"
              /> */}
              <TextField
                label="Designation"
                name="designation"
                variant="outlined"
                fullWidth
                value={formData.designation}
                onChange={handleInputChange}
                className="bg-gray-100"
              />
              {/* <TextField
                label="Username"
                name="username"
                variant="outlined"
                fullWidth
                value={formData.username}
                onChange={handleInputChange}
                className="bg-gray-100"
              />
              <TextField
                label="Password"
                name="password"
                variant="outlined"
                fullWidth
                value={formData.password}
                onChange={handleInputChange}
                className="bg-gray-100"
                type="password"
              /> */}
            </div>
          </div>
          <div style={{ width: "50%", marginLeft: "auto" }}>
            {error && (
              <Typography color="error" sx={{ marginBottom: "8px" }}>
                {error}
              </Typography>
            )}
            <Button
              sx={{
                height: "48px",
                marginLeft: "auto",
                backgroundColor: "#F59E0B",
                color: "white",
                "&:hover": {
                  backgroundColor: "#D97706",
                },
                marginTop: "12px",
                marginBottom: "12px",
                borderRadius: "999px",
                padding: "0 28px",
              }}
              onClick={handleSubmitButtonClick}
            >
              Save
            </Button>
          </div>

          <Typography
            className="text-center text-gray-500"
            sx={{ margin: "20px 0 40px 0" }}
          >
            Already have an account?{" "}
            <a href="/login" className="text-blue-600">
              Login here.
            </a>
          </Typography>
        </Stack>
      </Container>
      <RegistrationPopup
        open={isPopupOpen}
        handleClose={handlePopupClose}
        popupText="Thank you for registering your company. Login using the credentials you entered and complete the ISP Awards Questionnaire."
      />
    </div>
  );
};

export default Signup;
