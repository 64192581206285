import React, { useContext } from "react";
import "./createSurvey.css";
import Footer from "../../components/navbar/Footer";
import Navbar from "../../components/navbar/Navbar";
import { Button } from "@mui/material";
import { StepsContext } from "../../context/stepsContext";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchQuestions } from "../../redux/slices/questionsSlice";
import peoplesImage from "../../assets/welcomeBackground.jpg";
import { fetchAreas } from "../../redux/slices/areasSlice";

export default function DoSurvey() {
  const { dispatch: stepsDispatch } = useContext(StepsContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = async () => {
    stepsDispatch({ type: "START" });
    await dispatch(fetchQuestions());
    await dispatch(fetchAreas());
    navigate("/step1");
  };

  return (
    <div className="create-container">
      <Navbar />
      <div
        className="create-banner"
        style={{
          backgroundImage: `url(${peoplesImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
        }}
      >
        <div className="main-create-container">
          <div className="create-main xs:!w-11/12">
            <div className="create-main-header">ISP Awards Questionnaire</div>
            <div
              className="create-main-text text-lg"
              style={{ width: "100%", textAlign: "center" }}
            >
              <h3>
                Welcome to the ISP Awards evaluation questionnaire. This is your
                opportunity to share what your company is doing to build a high
                performing sales team.
              </h3>
            </div>
            <div className="create-main-small">
              There are 15 areas, each containing 5 questions. Kindly answer all
              of them by moving the slider. If you do not complete the survey in
              one go, the system will not store the information and you will
              have to start all over again.
            </div>
            <div className="create-main-btn">
              <Button
                aria-label="ddd"
                onClick={handleClick}
                className="signupCVbtnSign"
                type="submit"
                variant="contained"
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  padding: "8px 30px",
                  textTransform: "capitalize",
                  borderRadius: "999px",
                  backgroundColor: "#3F9BFD",
                  "&.css-ke7xld-MuiButtonBase-root-MuiButton-root:hover": {
                    backgroundColor: "#3F9BFD",
                  },
                }}
              >
                Click Here To Start
              </Button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
