import { useEffect, useState } from "react";

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const companyData = localStorage.getItem("companyData");
    if (companyData) {
      const parsedCompanyData = JSON.parse(companyData);
      // Check if the username exists in the data
      if (parsedCompanyData.username) {
        setIsAuthenticated(true);
      }
    }
  }, []);

  return isAuthenticated;
};

export default useAuth;
