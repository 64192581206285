import React from "react";
import logo from "../../assets/isp_logo_new.png";
export default function Navbar() {
  return (
    <div className="nav-create">
      <div className="image-container">
        <img className="" src={logo} alt="" />{" "}
      </div>

      {/* <>This will take few minutes</> */}
    </div>
  );
}
