import React, { useContext } from "react";
import { StepsContext } from "../../context/stepsContext";
import CheckIcon from "@mui/icons-material/Check";
import "./steps.css";

export default function Steps({ step, active, er }) {
  const { step: allStep } = useContext(StepsContext);

  const steps = Array.from({ length: 15 }, (_, index) => {
    const stepNumber = index + 1;
    return {
      stepNumber,
      checkIcon: allStep > index,
    };
  });

  return (
    <div className="stepsContainer xs:!px-2">
      <div className="stepsTop">
        <div className="lineContainer"></div>
        <div className="circlesContainer">
          {steps?.map(({ stepNumber, checkIcon }) => (
            <div key={stepNumber}>
              <span
                style={{
                  background: stepNumber === step ? "#2497a6" : "auto",
                }}
              >
                <div>
                  {checkIcon ? (
                    <CheckIcon
                      sx={{ width: "100%", height: "100%", color: "#2497a6" }}
                    />
                  ) : (
                    <p
                      style={{
                        color: stepNumber === step ? "white" : "#000",
                        fontSize: "10pt",
                      }}
                    >
                      {stepNumber}
                    </p>
                  )}
                </div>
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
