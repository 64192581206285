import { Stack, Typography } from "@mui/material";
import React from "react";

export default function Footer() {
  return (
    <div className="create-footer">
      <Stack>
        <Typography className="xs:!text-sm sm:!text-md">
          {" "}
          © The ISP |{" "}
          <a
            href={`https://www.the-isp.org/privacy-policy/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy |
          </a>{" "}
          <a
            href={`https://www.the-isp.org/cookie-policy/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookie Policy |
          </a>{" "}
          <a
            href={`https://www.the-isp.org/gdpr-and-data-protection-policy/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            GDPR and Data Protection Policy
          </a>
        </Typography>
      </Stack>
    </div>
  );
}
