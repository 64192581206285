import { configureStore } from "@reduxjs/toolkit";
import questionsReducer from "./slices/questionsSlice";
import areasReducer from "./slices/areasSlice";

const store = configureStore({
  reducer: {
    questions: questionsReducer,
    areas: areasReducer,
  },
});

export default store;
