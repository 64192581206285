import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import LoginLogo from "../../assets/isp_logo_new.png";

const Welcome = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleRegisterClick = () => {
    navigate("/register");
  };

  const handleForgotPasswordClick = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-200">
      <div className="bg-white rounded-lg shadow-lg px-8 py-4 w-full max-w-lg">
        <div className="flex flex-col items-center mb-4">
          <img
            src={LoginLogo}
            className="w-auto h-40 object-contain mb-0"
            alt="Logo"
          />
        </div>
        <div className="bg-[#04192E] text-white rounded-lg px-6 pb-16 pt-12 mb-5">
          <p className="mb-5 text-center text-lg">
            Welcome to the ISP Awards in Sales Excellence.
          </p>
          <Button
            variant="contained"
            fullWidth
            style={{
              backgroundColor: "#4FB5B6",
              color: "white",
              borderRadius: "999px",
              marginBottom: "32px",
              marginTop: "20px",
              fontSize: "12px",
              fontWeight: "bold",
              padding: "12px 0",
            }}
            onClick={handleLoginClick}
          >
            Login if you already have an account
          </Button>
          <Button
            variant="contained"
            fullWidth
            style={{
              backgroundColor: "#F9D15F",
              color: "black",
              borderRadius: "999px",
              marginBottom: "30px",
              fontSize: "12px",
              fontWeight: "bold",
              padding: "12px 0",
            }}
            onClick={handleRegisterClick}
          >
            Don’t have an account? Register now
          </Button>
          <Button
            variant="text"
            fullWidth
            style={{
              color: "white",
              textDecoration: "underline",
              fontSize: "0.9rem",
              textTransform: "capitalize",
            }}
            onClick={handleForgotPasswordClick}
          >
            Forgot Password?
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
