const StepsReducer = (state, action) => {
  switch (action.type) {
    case "STEP":
      return {
        ...state,
        step: state.step + 1,
      };
    case "START":
      return {
        ...state,
        start: true,
      };

    default:
      return state;
  }
};

export default StepsReducer;
