import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import DoSurvey from "./pages/surveys/DoSurvey";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import Welcome from "./pages/welcome/Welcome";
import "./pages/surveys/cards.css";
import "./pages/surveys/createSurvey.css";
import StepOne from "./pages/surveys/StepOne";
import StepTwo from "./pages/surveys/StepTwo";
import StepThree from "./pages/surveys/StepThree";
import StepFour from "./pages/surveys/StepFour";
import StepFive from "./pages/surveys/StepFive";
import StepSix from "./pages/surveys/StepSix";
import Step7 from "./pages/surveys/Step7";
import Step8 from "./pages/surveys/Step8";
import Step9 from "./pages/surveys/Step9";
import Step10 from "./pages/surveys/Step10";
import Step11 from "./pages/surveys/Step11";
import Step12 from "./pages/surveys/Step12";
import Step13 from "./pages/surveys/Step13";
import Step14 from "./pages/surveys/Step14";
import Step15 from "./pages/surveys/Step15";
import Result from "./pages/result/Result";
import useAuth from "./hooks/useAuth";
import ConfirmRegistration from "./pages/signup/ConfirmRegistration";

const App = () => {
  const isAuthenticated = useAuth();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Signup />} />
        <Route path="/verifyEmail/:key" element={<ConfirmRegistration />} />
        <Route
          path="/doSurvey"
          element={
            isAuthenticated ? <DoSurvey /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step1"
          element={
            isAuthenticated ? <StepOne /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step2"
          element={
            isAuthenticated ? <StepTwo /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step3"
          element={
            isAuthenticated ? <StepThree /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step4"
          element={
            isAuthenticated ? <StepFour /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step5"
          element={
            isAuthenticated ? <StepFive /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step6"
          element={
            isAuthenticated ? <StepSix /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step7"
          element={
            isAuthenticated ? <Step7 /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step8"
          element={
            isAuthenticated ? <Step8 /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step9"
          element={
            isAuthenticated ? <Step9 /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step10"
          element={
            isAuthenticated ? <Step10 /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step11"
          element={
            isAuthenticated ? <Step11 /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step12"
          element={
            isAuthenticated ? <Step12 /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step13"
          element={
            isAuthenticated ? <Step13 /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step14"
          element={
            isAuthenticated ? <Step14 /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/step15"
          element={
            isAuthenticated ? <Step15 /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="result"
          element={
            isAuthenticated ? <Result /> : <Navigate to="/login" replace />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
