import { createContext, useReducer } from "react";
import StepsReducer from "./stepsReducer";

const INITIAL_STATE = {
  step: 0,
  start: false,
};

export const StepsContext = createContext(INITIAL_STATE);

export const StepsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(StepsReducer, INITIAL_STATE);
  console.log(state);
  return (
    <StepsContext.Provider
      value={{
        step: state.step,
        start: state.start,
        dispatch,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
};
